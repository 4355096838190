export class Timer {
  static msToNextHour() {
    return 3600000 - (new Date().getTime() % 3600000);
  }

  static currentMinute() {
    return new Date().getMinutes();
  }

  static currentSecond() {
    return new Date().getSeconds();
  }
}
