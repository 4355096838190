import { COMPLETE_HOUR } from "../../../constants";
import { Pomodore } from "../../../entities/Pomodore";
import { PomoStates } from "../../../entities/PomoStates";

export class PomoInteractor {
  pomo: Pomodore;

  constructor(pomodore: Pomodore) {
    this.pomo = pomodore;
  }

  focusMinutesCheckpoints() {
    let pomoFocusTimeInterator = this.offsets().focusOffset;
    let result: number[] = [];

    while (pomoFocusTimeInterator <= COMPLETE_HOUR) {
      result.push(pomoFocusTimeInterator);
      pomoFocusTimeInterator += this.pomo.focusTime + this.pomo.breakTime;
    }

    return result;
  }

  breakMinutesCheckpoints() {
    let pomoFocusTimeInterator = this.offsets().breakOffset;
    let result: number[] = [];

    while (pomoFocusTimeInterator <= COMPLETE_HOUR) {
      result.push(pomoFocusTimeInterator);
      pomoFocusTimeInterator += this.pomo.focusTime + this.pomo.breakTime;
    }

    return result;
  }

  offsets() {
    return {
      breakOffset:
        this.pomo.initialPomoState === PomoStates.BREAK
          ? 0
          : this.pomo.focusTime,
      focusOffset:
        this.pomo.initialPomoState === PomoStates.FOCUS
          ? 0
          : this.pomo.breakTime,
    };
  }

  startBreak() {
    this.pomo.pomoState = PomoStates.BREAK;
  }

  startFocus() {
    this.pomo.pomoState = PomoStates.FOCUS;
  }

  currentPomoState(currentMinute: number) {
    return this.isInBreak(currentMinute) ? PomoStates.BREAK : PomoStates.FOCUS;
  }

  isInBreak(currentMinute: number) {
    for (const breakCheckpoint of this.breakMinutesCheckpoints()) {
      let currentMinuteBetweenBreak =
        currentMinute >= breakCheckpoint &&
        currentMinute < breakCheckpoint + this.pomo.breakTime;

      if (currentMinuteBetweenBreak) return true;
    }

    return false;
  }

  isInFocus(currentMinute: number) {
    return !this.isInBreak(currentMinute);
  }
}
