export async function requestPermission() {
  let permission = await Notification.requestPermission();

  return permission;
}

export function sendNotification(
  title: string,
  message: string,
  icon?: string
) {
  const notification = new Notification(title, { body: message, icon });

  setTimeout(() => {
    notification.close();
  }, 5000);
}
