import { useCallback, useEffect, useState } from "react";
import { PomoStates } from "../../lib/entities/PomoStates";
import { Pomodore } from "../../lib/entities/Pomodore";
import { PomoInteractor } from "../../lib/useCases/pomos/pomoInteractor";
import { Timer } from "../../lib/entities/Timer";
import { TimerInteractor } from "../../lib/useCases/timers/timerInteractor";
import { zeroPad } from "../../utils/zeroPad";
import { sendNotification } from "../../utils/notifications";
import GreenCatIcon from "../../assets/icons/green-cat-icon.png";
import RedCatIcon from "../../assets/icons/red-cat-icon.png";
import GreenCatFavicon from "../../assets/icons/green-cat.png";
import RedCatFavicon from "../../assets/icons/red-cat.png";
import { changeFavicon } from "../../utils/faviconChange";

export interface UsePomoTimerProps {
  breakTime: number;
  focusTime: number;
  initialPomoState: PomoStates;
}
export function usePomoTimer({
  breakTime,
  focusTime,
  initialPomoState,
}: UsePomoTimerProps) {
  const [minutesToNextState, setMinutesToNextState] = useState("");
  const [secondsToNextState, setSecondsToNextState] = useState("");
  const [currentState, setCurrentState] = useState<PomoStates>();

  useEffect(() => {
    currentState === PomoStates.BREAK
      ? changeFavicon(GreenCatFavicon)
      : changeFavicon(RedCatFavicon);
  }, [currentState]);

  const initialize = useCallback(() => {
    const pomo = new Pomodore(breakTime, focusTime, initialPomoState);
    const pomoInteractor = new PomoInteractor(pomo);
    setCurrentState(pomoInteractor.currentPomoState(Timer.currentMinute()));

    const timerInteractor = new TimerInteractor({
      pomo,
      onFocusStart: () => {
        setCurrentState(PomoStates.FOCUS);
        sendNotification("Focus time started!", "Let's focus!", RedCatIcon);
      },
      onBreakStart: () => {
        setCurrentState(PomoStates.BREAK);
        sendNotification(
          "Break time started!",
          "Have a break! Have a kitkat!",
          GreenCatIcon
        );
      },
      onClockTick: (
        currentMinute,
        currentSecond,
        minutesToNextState,
        secondsToNextState
      ) => {
        const formattedMinutesToNextState = zeroPad(minutesToNextState, 2);
        const formattedSecondsToNextState = zeroPad(secondsToNextState, 2);
        setMinutesToNextState(formattedMinutesToNextState);
        setSecondsToNextState(formattedSecondsToNextState);
        document.title = `${formattedMinutesToNextState}:${formattedSecondsToNextState}  •  Pomo Team`;
      },
    });

    if (currentState) timerInteractor.startTimer();

    return timerInteractor;
  }, [breakTime, focusTime, initialPomoState, currentState]);

  return {
    initialize,
    currentState,
    minutesToNextState,
    secondsToNextState,
  };
}
