import React, { useEffect } from "react";
import "./App.css";
import { PomoStates } from "./lib/entities/PomoStates";
import { usePomoTimer } from "./hooks/usePomoTimer";
import LottieAnimation from "./components/LottieAnimation";
import CatGreenAnimation from "./assets/animations/cat-green.json";
import CatRedAnimation from "./assets/animations/cat-red.json";
import { requestPermission } from "./utils/notifications";
import { getTimesFromSearch } from "./utils/searchParams";

function App() {
  const { minutesToNextState, secondsToNextState, initialize, currentState } =
    usePomoTimer({
      breakTime: parseInt(getTimesFromSearch().breakTime || "10"),
      focusTime: parseInt(getTimesFromSearch().focusTime || "50"),
      initialPomoState: PomoStates.FOCUS,
    });

  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    const timerInteractor = initialize();

    return () => timerInteractor.stopTimer();
  }, [initialize]);

  return (
    <div className="App">
      <div
        className="container"
        style={{
          backgroundColor:
            currentState === PomoStates.BREAK ? "#89CAFFFF" : "#FF8D89FF",
          transition: "all 5s ease",
          WebkitTransition: "all 5s ease",
          MozTransition: "all 5s ease",
        }}
      >
        <div className="animation-container">
          <LottieAnimation
            animationData={CatRedAnimation}
            style={{
              maxWidth: 500,
              maxHeight: 500,
              opacity: currentState === PomoStates.BREAK ? 0 : 1,
              transition: "all 5s ease",
              WebkitTransition: "all 5s ease",
              MozTransition: "all 5s ease",
              position: "absolute",
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />

          <LottieAnimation
            animationData={CatGreenAnimation}
            style={{
              maxWidth: 500,
              maxHeight: 500,
              opacity: currentState === PomoStates.BREAK ? 1 : 0,
              transition: "all 5s ease",
              WebkitTransition: "all 5s ease",
              MozTransition: "all 5s ease",
              position: "absolute",
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />
        </div>

        <div className="timer-container">
          <p className="countdown-text">
            {minutesToNextState}:{secondsToNextState}
          </p>
          <p>{currentState}</p>
        </div>
      </div>
    </div>
  );
}

export default App;
