import { COMPLETE_HOUR } from "../../constants";
import { PomoStates } from "../PomoStates";

export class Pomodore {
  pomoState: PomoStates;
  initialPomoState: PomoStates;
  breakTime: number;
  focusTime: number;

  constructor(
    breakTime: number,
    focusTime: number,
    initialPomoState: PomoStates
  ) {
    this.pomoState = initialPomoState;
    this.initialPomoState = initialPomoState;
    this.breakTime = breakTime;
    this.focusTime = focusTime;
  }

  validPomo() {
    return COMPLETE_HOUR % (this.breakTime + this.focusTime) === 0;
  }
}
