import { Pomodore } from "../../../entities/Pomodore";
import { Timer } from "../../../entities/Timer";
import { PomoInteractor } from "../../pomos/pomoInteractor";

type onClockTickType = (
  currentMinute: number,
  currentSecond: number,
  minutesToNextState: number,
  secondsToNextState: number
) => void;
type voidFunctionType = () => void;

export interface TimerInteractorProps {
  pomo: Pomodore;
  onFocusStart: voidFunctionType;
  onBreakStart: voidFunctionType;
  onClockTick: onClockTickType;
}

export class TimerInteractor {
  private props: TimerInteractorProps;
  private pomoInteractor: PomoInteractor;
  private timer: NodeJS.Timer | undefined;

  constructor(props: TimerInteractorProps) {
    this.props = props;
    this.pomoInteractor = new PomoInteractor(props.pomo);
  }

  timeToNextState(
    currentMinute: number,
    currentSecond: number,
    pomoCheckPoints: number[]
  ) {
    let nextPomoMinute = Math.max.apply(null, pomoCheckPoints);

    for (let i = 0; i < pomoCheckPoints.length; i++) {
      if (
        pomoCheckPoints[i] > currentMinute &&
        pomoCheckPoints[i] < nextPomoMinute
      )
        nextPomoMinute = pomoCheckPoints[i]; //Check if it's higher than your number, but lower than your closest value
    }

    const offset = currentSecond === 0 ? 0 : 1;
    const minutesToNextState = nextPomoMinute - (currentMinute + offset);
    const secondsToNextState = currentSecond === 0 ? 0 : 60 - currentSecond;

    return {
      minutesToNextState,
      secondsToNextState,
    };
  }

  startTimer() {
    const oneSecond = 1000;
    const breakMinutes = this.pomoInteractor.breakMinutesCheckpoints();
    const focusMinutes = this.pomoInteractor.focusMinutesCheckpoints();
    const checkPoints = breakMinutes.concat(focusMinutes);

    this.timer = setInterval(() => {
      const currentMinute = Timer.currentMinute();
      const currentSecond = Timer.currentSecond();
      const { minutesToNextState, secondsToNextState } = this.timeToNextState(
        currentMinute,
        currentSecond,
        checkPoints
      );

      this.props.onClockTick(
        currentMinute,
        currentSecond,
        minutesToNextState,
        secondsToNextState
      );

      if (currentSecond === 0) {
        if (breakMinutes.includes(currentMinute)) {
          this.props.onBreakStart();
        } else if (focusMinutes.includes(currentMinute)) {
          this.props.onFocusStart();
        }
      }
    }, oneSecond);
  }

  stopTimer() {
    clearInterval(this?.timer);
  }
}
